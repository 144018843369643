/* Container for header section with centered alignment */
.h-cnt {
    flex: 1; /* Allows the content to grow and fill available space, pushing the footer down */
    width: 100%; /* Full width */
    display: flex; /* Use flexbox layout */
    flex-direction: column; /* Arrange items in a column */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
}

/* Container for image section with centered alignment */
.hi-cnt {
    width: 80%; /* 80% of the parent width */
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items horizontally */
}

/* Styling for images inside image container */
.hi-cnt img {
    align-self: center; /* Center image itself */
    width: 50%; /* 50% of the parent width */
    padding: 3%; /* Padding around the image */
    max-width: 800px; /* Maximum width */
}

/* Container for text section with background and shadow */
.hi-txt-cnt {
    margin-top: 4%; /* Top margin */
    margin-bottom: 4%; /* Bottom margin */
    padding: 3%; /* Padding around the content */
    padding-top: 0; /* No padding on top */
    background-color: rgba(185, 228, 247, 0.4667); /* Background color with opacity */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); /* Box shadow for depth */
    display: flex; /* Use flexbox layout */
    flex-direction: column; /* Arrange items in a column */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
}

/* Styling for text title */
.hi-txt-cnt .txt-title {
    font-size: 24px; /* Font size */
    letter-spacing: -1.125px; /* Letter spacing */
    font-weight: 500; /* Font weight */
}

/* Styling for general text */
.h-txt {
    font-size: 18px; /* Font size */
    line-height: 30px; /* Line height */
    letter-spacing: 0.09px; /* Letter spacing */
    font-weight: 300; /* Font weight */
}

/* Container for image section with background and shadow */
.ab-img-cnt {
    background-color: rgba(185, 228, 247, 0.4667); /* Background color with opacity */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); /* Box shadow for depth */
    width: 80%; /* 80% of the parent width */
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items horizontally */
}

/* Container for individual image with padding and center alignment */
.ab {
    width: 23%; /* 23% of the parent width */
    margin: 4%; /* Margin around the container */
    padding: 2%; /* Padding around the content */
    line-height: 30px; /* Line height */
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    text-align: center; /* Center text horizontally */
}

/* Styling for images inside individual image container */
.ab-img {
    width: 80%; /* 80% of the parent width */
    padding: 10px; /* Padding around the content */
}

/* Styling for images inside the nested image container */
.ab-img img {
    padding: 30px; /* Padding around the image */
    width: 35%; /* 35% of the parent width */
}

/* Container for 'What We Offer' section with background and shadow */
.wwo-cnt {
    background-color: rgba(185, 228, 247, 0.4667); /* Background color with opacity */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); /* Box shadow for depth */
    margin-top: 4%; /* Top margin */
    width: 60%; /* 60% of the parent width */
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

/* Container for text inside 'What We Offer' section */
.wwo-txt {
    margin-top: 4%; /* Top margin */
    margin-bottom: 4%; /* Bottom margin */
    padding: 3%; /* Padding around the content */
    padding-top: 0; /* No padding on top */
    display: flex; /* Use flexbox layout */
    flex-direction: column; /* Arrange items in a column */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
}

/* Styling for main heading in 'What We Offer' section */
.wwo-txt h1 {
    font-size: 24px; /* Font size */
    line-height: 30px; /* Line height */
    letter-spacing: -1.125px; /* Letter spacing */
    font-weight: 500; /* Font weight */
}

/* Styling for subheading in 'What We Offer' section */
.wwo-txt h2 {
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    text-align: center; /* Center text horizontally */
}

/* Container for footer section with background and shadow */
.hf-cnt {
    background-color: rgba(185, 228, 247, 0.4667); /* Background color with opacity */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); /* Box shadow for depth */
    margin-bottom: 100px; /* Bottom margin */
    margin-top: 4%; /* Top margin */
    width: 80%; /* 80% of the parent width */
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

/* Container for large image in footer section */
.hf-lg-img-cnt {
    width: 75%; /* 35% of the parent width */
    padding: 10px; /* Padding around the content */
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text horizontally */
}

/* Styling for large image in footer section */
.hf-lg-img-cnt img {
    width: 80%; /* 90% of the parent width */
    padding: 10px; /* Padding around the content */
}

/* Container for text in footer section */
.hf-sml-txt-cnt {
    width: 40%; /* 60% of the parent width */
}

/* Styling for main heading in footer section */
.hf-sml-txt-cnt h1 {
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text horizontally */
    font-size: 24px; /* Font size */
    line-height: 30px; /* Line height */
    letter-spacing: -1.125px; /* Letter spacing */
    font-weight: 500; /* Font weight */
}

/* Styling for text in footer section */
.hf-sml-txt-cnt h2{
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Center text horizontally */
}

/* Container for small images in footer section */
.hf-sml-img-cnt {
    width: 60%; /* 60% of the parent width */
    padding: 10px; /* Padding around the content */
    display: flex; /* Use flexbox layout */
    flex-direction: column; /* Arrange items in a column */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
}

/* Styling for small images in footer section */
.hf-sml-img-cnt img {
    padding: 10px; /* Padding around the image */
    width: 75%; /* 35% of the parent width */
}


/* Media query for screen sizes 768px or less */
@media screen and (max-width: 768px) {

}
  
/* Media query for screen sizes 480px or less */
@media screen and (max-width: 480px) {
    /* Container for image section with centered alignment */
    .hi-cnt {
        flex-direction: column; /* Arrange items in a column */
    }

    /* swap the child elements int the flexbox */
    .hi-cnt img {
        order: 2; /* Originally first, now second */
    }

    .hi-cnt .hi-txt-cnt {
        order: 1; /* Originally second, now first */
    }

    /* Styling for images inside image container */
    .hi-cnt img {
        width: 90%; /* 50% of the parent width */
        max-width: 300px; /* Maximum width */
    }

    /* Container for image section with background and shadow */
    .ab-img-cnt {
        margin-top: 4%; /* Top margin */
        flex-direction: column; /* Arrange items in a column */
        align-items: center;
    }

    /* Container for individual image with padding and center alignment */
    .ab {
        width: 90%; /* 23% of the parent width */
    }

    /* Styling for images inside the nested image container */
    .ab-img img {
        padding: 10px; /* Padding around the image */
        width: 100%; /* 35% of the parent width */
    }

    /* Container for 'What We Offer' section with background and shadow */
    .wwo-cnt {
        width: 70%; /* 60% of the parent width */
    }

    /* Container for text inside 'What We Offer' section */
    .wwo-txt {
        margin-top: 2%; /* Top margin */
    }

    /* Styling for main heading in 'What We Offer' section */
    .wwo-txt h1 {
        font-size: 20px; /* Font size */
    }

    /* Styling for subheading in 'What We Offer' section */
    .wwo-txt h2 {
        font-size: 16px; /* Font size */
        line-height: 25px; /* Line height */
    }

    /* Container for footer section with background and shadow */
    .hf-cnt {
        flex-direction: column; /* Arrange items in a column */
    }

    /* Container for large image in footer section */
    .hf-lg-img-cnt {
        width: 100%; /* 35% of the parent width */
        padding: 0px; /* Padding around the content */
    }

    /* Styling for large image in footer section */
    .hf-lg-img-cnt img {
        width: 90%; /* 90% of the parent width */
    }

    /* Container for text in footer section */
    .hf-sml-txt-cnt {
        width: 90%; /* 60% of the parent width */
    }

    /* Container for small images in footer section */
    .hf-sml-img-cnt {
        width: 100%; /* 60% of the parent width */
    }

    /* Styling for small images in footer section */
    .hf-sml-img-cnt img {
        width: 80%; /* 35% of the parent width */
    }
}