/* Main container for the calendar component */
.avcal-cnt {
    flex: 1; /* Allows the content to grow and fill available space, pushing the footer down */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Container for the calendar itself, including styling for margin, background, and shadow */
.cal-cnt {
    margin: 3%;
    background-color: rgba(185, 228, 247, 0.4667); /* Light blue background with opacity */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

/* Styles for the month view in the react-big-calendar library */
.rbc-month-view {
    border-collapse: collapse; /* Ensures borders between days are collapsed into a single border */
}

/* Styles for the headers and day backgrounds in the month view */
.rbc-header,
.rbc-day-bg {
  border-bottom: 1px solid #b3b3b3; /* Adds a subtle border to the bottom of headers and day backgrounds */
}

/* Container for text inside 'Calendar' section */
.cal-txt{
    background-color: rgba(185, 228, 247, 0.4667); /* Light blue background with 46.67% opacity */
    width: 100%; /* Full width */
    display: flex;  /* Flexbox layout */
    flex-direction: column; /* Arrange items in a column */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items horizontally */
    padding-top: 0; /* No padding on top */
}

/* Styling for main heading in 'What We Offer' section */
.cal-txt h1 {
    font-size: 18px; /* Font size */
    line-height: 20px; /* Line height */
    letter-spacing: -1.125px; /* Letter spacing */
    font-weight: 500; /* Font weight */
}

/* Styling for subheading in 'What We Offer' section */
.cal-txt h2 {
    font-size: 14px; /* Font size */
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    text-align: center; /* Center text horizontally */
    width: 40%; /* 50% of the parent width */
}

/* Media query for screen sizes 480px or less */
@media screen and (max-width: 480px) {
    .cal-txt h2 {
        width: 80%; /* 50% of the parent width */
    }
}