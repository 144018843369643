/* Styling for the image container*/
.img-slider-cnt{
    width: 90%; /* Full width of the parent container */
    max-width: 800px; /* Maximum width of 800px */
    min-width: 600px; /* Minimum width of 700px */
    height: 425px; /* Fixed height of 425px */
}

/* Styling for the active image */
.active-img {
    width: 100%; /* Full width of the parent container */
    max-width: 800px; /* Maximum width of 800px */
    min-width: 600px; /* Minimum width of 600px */
    height: 425px; /* Fixed height of 425px */
}

@media screen and (max-width: 480px) {

    /* Styling for the image container*/
    .img-slider-cnt{
        max-width: 400px; /* Maximum width of 800px */
        min-width: auto; /* Minimum width of auto */
        height: 300px; /* Fixed height of 300px */
        margin: 5%; /* Margin around the container */
    }

    /* Styling for the active image */
    .active-img {
        width: 100%; /* Full width of the parent container */
        max-width: 400px; /* Maximum width of 800px */
        min-width: auto; /* Minimum width of auto */
        height: 300px; /* Fixed height of 300px */
    }
}