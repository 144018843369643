/* Centering container with flexbox and padding */
.cf-cnt {
  flex: 1; /* Allows the content to grow and fill available space, pushing the footer down */
  display: flex; /* Use flexbox layout */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  padding: 50px; /* Padding around the container */
  height: 90vh; /* 90% of the viewport height */
}

/* Styling for the dividing line */
.line {
  border-left: 1px solid rgb(114, 114, 114); /* Border color and width */
  height: 85%; /* 85% of the viewport height */
  max-height: 600px; /* Maximum height */
  min-height: 500px; /* Minimum height */
  margin: 30px; /* Margin around the line */
  padding-left:30px; /* Padding on the left side */
}

/* Styling for the main form container */
.cf {
  display: flex; /* Use flexbox layout */
  flex-direction: column; /* Arrange items in a column */
  align-items: center; /* Center items horizontally */
  justify-content: center;
  background-color: rgba(185, 228, 247, 0.4667); /* Background color with opacity */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); /* Box shadow for depth */
  height: 90%; /* 90% of the viewport height */
  max-height: 600px; /* Maximum height */
  min-height: 500px; /* Minimum height */
  width: 80%; /* 30% of the parent width */
  margin-left: 20px; /* Margin on the left side */
}

/* Styling for the form title */
.cf .f-title {
  margin-top: 5px; /* Top margin */
  line-height: 40px; /* Line height */
  font-size: 20px; /* Font size */
  font-weight: 500; /* Font weight */
  color: black; /* Text color */
}

/* Styling for the side image */
.side-img {
  width: 30%; /* 30% of the parent width */
  max-width: 400px;
  padding: 30px; /* Padding around the image */
}

/* Input field styling */
.f-input {
  border-radius: 3px; /* Rounded corners */
  font-family: 'Playfair Display', 'Merriweather', 'Cormorant Garamond', 'Lora', 'Bitter', serif; /* Font family */
  font-size: 16px; /* Font size */
  border: none; /* No border */
}

/* Input field focus state */
.f-input:focus {
  outline: none; /* Removes the default focus outline */
}

/* Input group styling */
.cf .f-input-gp1 {
  margin-top: 10px; /* Top margin */
  width: 80%; /* 80% of the parent width */
  display: flex; /* Use flexbox layout */
  flex-direction: row; /* Arrange items in a row */
  justify-content: space-evenly; /* Distribute space evenly */
}

/* Individual input group element styling */
.f-input-gp1-elemt {
  width: 40%; /* 40% of the parent width */
  display: flex; /* Use flexbox layout */
  flex-direction: column; /* Arrange items in a column */
}

/* Styling for labels in input group elements */
.f-input-gp1-elemt label {
  font-size: 14px; /* Font size */
  font-weight: 300; /* Font weight */
  color: black; /* Text color */
}

/* Styling for input with logo */
.f-input-logo {
  display: flex; /* Use flexbox layout */
  flex-direction: row; /* Arrange items in a row */
  border-radius: 3px; /* Rounded corners */
  background-color: white; /* Background color */
  opacity: 0.75; /* Slightly transparent */
}

/* Input field inside logo container */
.f-input-logo input {
  width: 75%; /* 75% of the parent width */
}

/* SVG icon inside logo container */
.f-input-logo svg {
  padding: 5px; /* Padding around the icon */
}

/* Individual input element styling */
.f-input-elemt {
  margin-top: 10px; /* Top margin */
  width: 70%; /* 70% of the parent width */
  display: flex; /* Use flexbox layout */
  flex-direction: column; /* Arrange items in a column */
}

/* Styling for labels in input elements */
.f-input-elemt label {
  font-size: 14px; /* Font size */
  font-weight: 300; /* Font weight */
  color: black; /* Text color */
}

/* Styling for text area input */
.f-input-note {
  border-radius: 3px; /* Rounded corners */
  font-family: 'Playfair Display', 'Merriweather', 'Cormorant Garamond', 'Lora', 'Bitter', serif; /* Font family */
  font-size: 16px; /* Font size */
  border: none; /* No border */
  height: 100px; /* Height of the text area */
  white-space: pre-wrap; /* Ensure text wraps */
  overflow-y: auto; /* Show vertical scrollbar when needed */
  resize: none; /* Allow vertical resizing only */
}

/* Text area inside logo container */
.f-input-logo textarea {
  width: 75%; /* 75% of the parent width */
}

/* Button styling */
.cf button {
  font-family: 'Playfair Display', 'Merriweather', 'Cormorant Garamond', 'Lora', 'Bitter', serif; /* Font family */
  margin-top: 20px; /* Top margin */
  margin-bottom: 10px; /* Bottom margin */
  padding: 5px 10px; /* Padding */
  background-color: #007BFF; /* Background color */
  color: #f4f4f4; /* Text color */
  font-size: 12px; /* Font size */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
}

/* Button hover state */
.cf button:hover {
  background-color: #0056b3; /* Darker background color */
}

/* Media query for screen size 768px or less */
@media screen and (max-width: 768px) {

}

/* Media query for screen size 480px or less */
@media screen and (max-width: 480px) {
  .cf-cnt {
      display: flex; /* Use flexbox layout */
      flex-direction: column-reverse; /* Arrange items in a column in reverse order */
      padding: 0px; /* No padding */
      height: 100%; /* Full height */
  }

  .line {
      border-bottom: 1px solid rgb(114, 114, 114); /* Border color and width */
      border-left: none; /* Border color and width */
      width: 85vw; /* 85% of the viewport width */
      padding-left: 0px; /* Padding on the left side */
      padding-bottom: 30px; /* Padding on the bottom */
  }

  .side-img {
      max-width: 70%; /* 60% of the parent width */
      padding: 30px; /* Padding around the image */
      width: 60%; /* 60% of the parent width */
  }

  .cf {
      display: flex; /* Use flexbox layout */
      flex-direction: column; /* Arrange items in a column */
      align-items: center; /* Center items horizontally */
      background-color: rgba(185, 228, 247, 0.4667); /* Background color with opacity */
      border-radius: 10px; /* Rounded corners */
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); /* Box shadow for depth */
      min-height: fit-content; /* Minimum height to fit content */
      min-width: fit-content; /* Minimum width to fit content */
      margin: 20px; /* Margin around the form */
  }
}
