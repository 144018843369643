/* Container for properties section with horizontal layout */
.prop-cnt {
    flex: 1; /* Allows the content to grow and fill available space, pushing the footer down */
    display: flex; /* Use flexbox layout */
    flex-direction: row; /* Arrange items in a row */
    margin-bottom: 50px; /* Bottom margin */
    padding-top: 30px; /* Top padding */
    justify-content: center; /* Center items horizontally */
}

/* Container for the main slider image */
.slider-img-cnt {
    width: 55%; /* 55% of the parent width */
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items horizontally */
    align-items: center;
}

/* Container for image selection with vertical layout and border */
.img-sel-cnt {
    width: 30%; /* 30% of the parent width */
    padding: 5%; /* Padding around the content */
    border-left: 2px solid rgb(114, 114, 114); /* Left border */
    display: flex; /* Use flexbox layout */
    flex-direction: column; /* Arrange items in a column */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
}

/* Styling for image selection buttons */
.img-sel-btn {
    padding: 5px; /* Padding around the button */
    width: 200px; /* Fixed width */
}

/* Container for image selection text with background and shadow */
.img-sel-txt {
    width: 250px; /* Fixed width */
    height: 350px; /* 70% of the parent height */
    padding: 20px; /* Padding around the content */
    margin-top: 20px; /* Top margin */
    background-color: rgba(185, 228, 247, 0.4667); /* Background color with opacity */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1); /* Box shadow for depth */
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    text-align: center; /* Center text horizontally */

    font-family: 'Times New Roman', 'Playfair Display', 'Merriweather', 'Cormorant Garamond', 'Lora', 'Bitter', serif; /* Font family for the application */
    color: black; /* Text color */
    font-weight: 300; /* Font weight */
    font-size: 20px;
}

/* Media query for screen size 768px or less */
@media screen and (max-width: 768px) {

}

/* Media query for screen size 480px or less */
@media screen and (max-width: 480px) {
    .prop-cnt {
        flex-direction: column; /* Arrange items in a column */
        justify-content: center; /* Center items horizontally */
        padding: 10px; /* Padding around the container */
    }

    /* Container for the main slider image */
    .slider-img-cnt {
        width: 100%; /* 100% of the parent width */
    }
    
    .img-sel-cnt {
        border-left: none; /* Remove left border */
        margin-left: 0px; /* No left margin */
        padding: 0px; /* No padding */
        margin-top: 20px; /* Top margin */
        padding-top: 20px; /* Top padding */
        border-top: 1px solid rgb(114, 114, 114); /* Top border */
        width: 100%; /* Full width */
    }

    /* Container for image selection text with background and shadow */
    .img-sel-txt {
        height: 70%; /* 70% of the parent height */
    }

}
