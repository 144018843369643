/* Body styling with a light blue background color */
body {
  background-color: rgba(185, 228, 247, 0.30); /* Light blue background with 30% opacity */
  display: flex; /* Use flexbox to arrange children */
  flex-direction: column; /* Stack children vertically */
  justify-content: space-between; /* Align children to start and end of container */
}

/* Main application container styling */
.App {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh; /* Ensure it's at least as tall as the viewport */
  font-family: 'Playfair Display', 'Merriweather', 'Cormorant Garamond', 'Lora', 'Bitter', serif; /* Font family for the application */
}

/* Navigation container styling */
.Nav-container {
  display: flex; /* Flexbox layout */
  align-items: center; /* Center items vertically */
  padding: 4%; /* Padding around the container */
  color: rgb(48, 70, 116); /* Text color */
  font-weight: 400; /* Font weight */
  background-color: rgba(185, 228, 247, 0.4667); /* Light blue background with 46.67% opacity */
  height: 3vh; /* Height of the navigation container */
}

/* Unordered list styling for navigation items */
ul {
  list-style-type: none; /* Remove default list style */
  display: flex; /* Flexbox layout */
  justify-content: flex-end; /* Align items to the right */
  margin: auto; /* Center the list */
  width: 100%; /* Full width */
}

/* List item styling */
li {
  margin-right: 2.5%; /* Right margin */
  margin-left: 2.5%; /* Left margin */
}

/* Anchor tag styling within list items */
ul li a {
  color: rgb(48, 70, 116); /* Text color */
  text-decoration: none; /* Remove underline */
}

/* Styling for the active anchor tag */
ul li a.active {
  color: rgb(48, 70, 116); /* Text color */
  font-weight: 600; /* Bold text */
}

/* Navigation title styling */
.Nav-title {
  font-size: 130%; /* Font size */
  font-weight: normal; /* Normal font weight */
}

/* Logo image styling */
.logo-image {
  max-width: 100px; /* Maximum width */
  margin-left: 10px; /* Left margin */
  margin-right: 10px; /* Right margin */
}

.f-cnt{
  font-weight: 400; /* Font weight */
  background-color: rgba(185, 228, 247, 0.4667); /* Light blue background with 46.67% opacity */

  display: flex; /* Flexbox layout */
  flex-direction: column; /* Vertical stacking */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  padding: 20px;
}

.f-cnt .f-gen-txt{
  font-size: 14px; /* Font size */
  display: flex; /* Use flexbox layout */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  text-align: center; /* Center text horizontally */
  width: 40%; /* 50% of the parent width */
  border-bottom: 1px solid #b3b3b3; /* Adds a subtle border to the bottom of headers and day backgrounds */
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.f-cnt ul{
  margin: 0; /* Remove default margin */
  display: flex; /* Flexbox layout */
  justify-content: space-evenly; /* Center items horizontally */
  font-size: 14px; /* Font size */
  width: 30%;
}

/* Anchor tag styling within list items */
.f-cnt ul li a {
  color: black; /* Text color */
  text-decoration: none; /* Remove underline */
}

/* Styling for the active anchor tag */
.f-cnt ul li a.active {
  color: black; /* Text color */
  font-weight: 600; /* Bold text */
}

/* Media query for screen sizes 768px or less */
@media screen and (max-width: 768px) {
  .Nav-title {
    font-size: 100%; /* Reduce font size */
  }
  
  li {
    margin-right: 2%; /* Adjust right margin */
    margin-left: 2%; /* Adjust left margin */
  }

  .logo-image {
    max-width: 60px; /* Reduce max width */
    margin-left: 0; /* Remove left margin */
    margin-right: 3px; /* Adjust right margin */
  }
}

/* Media query for screen sizes 480px or less */
@media screen and (max-width: 480px) {
  /* Navigation container styling */
  .Nav-container {
    display: flex; /* Flexbox layout */
    align-items: center; /* Center items vertically */
    
    color: rgb(48, 70, 116); /* Text color */
    font-weight: 400; /* Font weight */
    background-color: rgba(185, 228, 247, 0.4667); /* Light blue background with 46.67% opacity */

    height: 25%; /* Height of the navigation container */
  }

  .logo-image {
    max-width: 70px; /* Maintain reduced max width */
    margin-left: 5px; /* Adjust left margin */
    margin-right: 5px; /* Adjust right margin */
  }

  ul {
    list-style-type: none; /* Remove default list style */
    display: flex; /* Flexbox layout */
    flex-direction: column; /* Vertical stacking */
    align-items: flex-end; /* Align items to the right */
  }

  li {
    margin-right: 2.5%; /* Right margin */
    margin-left: 2.5%; /* Left margin */
    margin-bottom: 3px; /* Bottom margin for spacing */
  }

  .f-cnt{
    font-weight: 400; /* Font weight */
    background-color: rgba(185, 228, 247, 0.4667); /* Light blue background with 46.67% opacity */
  
    display: flex; /* Flexbox layout */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    padding: 5px;
  }

  .f-cnt .f-gen-txt{
    width: 80%; /* 80% of the parent width */
  }
  
  .f-cnt ul{
    flex-direction: row; /* Vertical stacking */
    align-items: center; /* Center items vertically */
    width: 80%;
  }
  
  .f-cnt ul{
    text-align: center;
  }
}
